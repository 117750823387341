import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { country, CountryService } from '@features/country/data';
import { TranslateModule } from '@ngx-translate/core';
import { queryString } from '@presentation/shared/router';
import { StoreModel } from 'app/core/domain/store.model';
import { UserStoreModel } from 'app/core/domain/user-store.model';
import { LogMixpanelEventUseCase } from 'app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'app/core/usecases/get-feature-flag.usecase';
import { getStoreRedirectUri } from 'app/data/repositories/merchant-store/utilities/get-store-redirect-uri.utility';
import {
  DUKAN_PROVIDER_NAME,
  EASY_ORDERS_PROVIDER_NAME,
  WOOCOMMERCE_PROVIDER_NAME,
  YOUCAN_PROVIDER_NAME,
  ZID_PROVIDER_NAME,
} from 'app/presentation/shared/constants';
import { UnlinkStorePopUpComponent } from 'app/presentation/stores/dukan/unlink-store-pop-up/unlink-store-pop-up.component';
import { YoucanCheckoutPopUpComponent } from 'app/presentation/stores/dukan/youcan-checkout-pop-up/youcan-checkout-pop-up.component';
import { StoresPresenter } from 'app/presentation/stores/presenter/stores.presenter';
import { StoresViewEvents } from 'app/presentation/stores/presenter/stores.view-events';
import {
  NewStoreModel,
  StoresViewState,
} from 'app/presentation/stores/presenter/stores.view-state';
import { WooCommerceFailureModalComponent } from 'app/presentation/stores/woo-commerce-failure-modal/woo-commerce-failure-modal.component';
import { WooCommerceModalComponent } from 'app/presentation/stores/woo-commerce-modal/woo-commerce-modal.component';
import { WooCommerceSuccessModalComponent } from 'app/presentation/stores/woo-commerce-success-modal/woo-commerce-success-modal.component';
import { YoucanLinkVideoComponent } from 'app/presentation/stores/youcan-link-video/youcan-link-video.component';
import { finalize } from 'rxjs';
import { DukanShopModel, StateStatuses } from '../../core/domain/dukan-shop/dukan-shop.model';
import { GetDukanShopUseCase } from '../../core/usecases/dukan-shop/get-dukan.usecase';
import { LinkMerchantStoreUseCase } from '../../core/usecases/merchant-store/link-merchant-store.usecase';
import { appUrlsConstantsInjectionToken } from '../../data/injection-tokens/app-urls-constants.injection-token';
import { BaseComponent } from '../base/base.component';
import { REVAMPED_STORES } from '../shared/constants/feature-flags';
import { ApiKeysIntegrationComponent } from './api-keys-integration/api-keys-integration.component';
import { DukanSuccessfullyCreatedComponent } from './dukan/dukan-successfully-created/dukan-successfully-created.component';
import { SetupYourDukanComponent } from './dukan/setup-your-dukan/setup-your-dukan.component';
import { StoreLinkedSuccessfullyComponent } from './dukan/store-linked-successfully/store-linked-successfully.component';
import { StoreLinkingErrorComponent } from './dukan/store-linking-error/store-linking-error.component';
import { OldStoresComponent } from './old-stores/old-stores.component';

// TODO: Display success/failure popups on store integration

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
  standalone: true,
  imports: [
    OldStoresComponent,
    ApiKeysIntegrationComponent,
    NgIf,
    NgFor,
    NgClass,
    TranslateModule,
    CommonModule,
    RouterLink,
  ],
  providers: [StoresPresenter],
})
export class StoresComponent
  extends BaseComponent<StoresPresenter, StoresViewState, StoresViewEvents>
  implements AfterViewInit
{
  revampedStoresEnabled = false;

  activeCourseIndex: number | null = null;

  activeQuestionIndex: number | null = null;

  contentQuestionHeights: number[] = [];

  contentCourseHeights: number[] = [];

  public currentCountryLinkedStore: UserStoreModel;

  stores = [];

  selectedCountry: CountryService;

  public sanitizedUrl: SafeUrl;

  public dukanShop: DukanShopModel;

  public dukanStates = StateStatuses;

  public isLoading = true;

  public isUserEligibleToDukan = true;

  @ViewChildren('accordionQuestionsContent') contentQuestionElements!: QueryList<ElementRef>;

  @ViewChildren('accordionCourseContent') contentCourseElements!: QueryList<ElementRef>;

  constructor(
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    public presenter: StoresPresenter,
    private _linkMerchantStoreUseCase: LinkMerchantStoreUseCase,
    private _dialog: MatDialog,
    private _modal: MatDialog,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _getShopUseCase: GetDukanShopUseCase,
    private _sanitizer: DomSanitizer,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {
    super();
    this._getFeatureFlagUseCase.execute(REVAMPED_STORES).subscribe((flag) => {
      this.revampedStoresEnabled = flag;
      this.selectedCountry = country;
    });
  }

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });

    this.checkStoresIntegrationState();
  }

  protected checkStoresIntegrationState(): void {
    const params = queryString.all();

    if (params.provider === ZID_PROVIDER_NAME && params.code === 'undefined') {
      window.location.href = getStoreRedirectUri(ZID_PROVIDER_NAME)!;

      return;
    }

    const success = queryString.get('success');

    if (success) {
      return this.displayWooCommerceIntegrationState();
    }

    const linkedStore = this.viewState.userStores.find(
      (store) => store.provider === params.provider,
    );

    if (!linkedStore) return;

    this.isLoading = true;

    this._logMixpanelEventUseCase.execute({
      eventName: 'click_link_store_button',
      payload: { provider: params.provider },
    });
    const location = `https://${window.location.hostname}`;
    this._linkMerchantStoreUseCase
      .execute({
        authCode: params.code,
        provider: params.provider,
        redirectUri: location + linkedStore!.redirectUri,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.getUserStores();
          this._logMixpanelEventUseCase.execute({
            eventName: 'click_link_store_successful_message',
            payload: {
              provider: params.provider,
              storeId: this.viewState.currentCountryLinkedStore?.storeId,
            },
          });
          this._dialog.open(StoreLinkedSuccessfullyComponent, {
            width: '450px',
            panelClass: 'linked-store-dialog',
            direction: this.direction,
          });
        },
        error: (err) => {
          this._logMixpanelEventUseCase.execute({
            eventName: 'click_link_store_failure_message',
            payload: { error: err.error.message, provider: params.provider },
          });
          this._dialog.open(StoreLinkingErrorComponent, {
            width: '450px',
            panelClass: 'linked-store-dialog',
            data: {
              provider: params.provider,
            },
          });
        },
      });
  }

  displayWooCommerceIntegrationState(): void {
    const success = queryString.get('success');

    if (!success) return;

    if (success === '1') {
      this.openSuccessWooCommerceModal();
    } else if (success === '0') {
      this.openFailureWooCommerceModal();
    }
  }

  getUserStores(): void {
    this.presenter.getUserStores();
  }

  onUnlinkStoreClicked(store: StoreModel): void {
    const dialogRef = this._dialog.open(UnlinkStorePopUpComponent, {
      width: '450px',
      panelClass: 'linked-store-dialog',
      direction: this.direction,
      data: {
        provider: store.provider,
        storeId: this.viewState.currentCountryLinkedStore?.storeId,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: () => {
        this.getUserStores();
      },
    });
    this._logMixpanelEventUseCase.execute({
      eventName: 'click_unlink_store_button',
      payload: {
        provider: store.provider,
        storeId: this.viewState.currentCountryLinkedStore?.storeId,
      },
    });
  }

  ngAfterViewInit(): void {
    this.contentQuestionHeights = this.contentQuestionElements.map(
      (el) => el.nativeElement.scrollHeight,
    );

    this.contentCourseHeights = this.contentCourseElements.map(
      (el) => el.nativeElement.scrollHeight,
    );
  }

  openSuccessWooCommerceModal(): void {
    this._modal.open(WooCommerceSuccessModalComponent, {
      direction: this.direction,
      width: '485px',
    });
  }

  openFailureWooCommerceModal(): void {
    this._modal.open(WooCommerceFailureModalComponent, {
      direction: this.direction,
      width: '485px',
    });
  }

  public openVideoDialog(): void {
    this._dialog.open(YoucanLinkVideoComponent, {
      width: '800px',
    });
    this._logMixpanelEventUseCase.execute({ eventName: 'you_can_video_button_clicked' });
  }

  toggleCourseAccordion(index: number): void {
    this.activeCourseIndex = this.activeCourseIndex === index ? null : index;
  }

  toggleQuestionAccordion(index: number): void {
    this.activeQuestionIndex = this.activeQuestionIndex === index ? null : index;
  }

  onStoreSelected(store: NewStoreModel): void {
    this.presenter.emitViewEvent({
      type: 'SelectionChanged',
      store,
    });

    this.viewState.selectedStore = store;
  }

  protected openWoocommerceModal(): void {
    const insertLinkModal = this._dialog.open(WooCommerceModalComponent, {
      direction: this.direction,
      panelClass: 'linked-store-dialog',
      width: '524px',
    });

    insertLinkModal.afterClosed().subscribe((res) => {
      if (res === true) {
        window.location.href = getStoreRedirectUri(this.viewState.selectedStore.name)!;
      }
    });
  }

  protected openYoucanModal(): void {
    const dialogRef = this._dialog.open(YoucanCheckoutPopUpComponent, {
      width: '900px',
      panelClass: 'linked-store-dialog',
      direction: this.direction,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        window.location.href = getStoreRedirectUri(this.viewState.selectedStore.provider!) || '';
      }
    });
  }

  protected openZidModal(): void {
    window.location.href = getStoreRedirectUri(this.viewState.selectedStore.provider!) || '';
  }

  protected setupDukanDialog(): void {
    const dialogRef = this._dialog.open(SetupYourDukanComponent, {
      width: '700px',
      panelClass: 'linked-store-dialog',
      direction: this.direction,
    });
    dialogRef.afterClosed().subscribe({
      next: (res) => {
        this.dukanShop = res;
        this.sanitizedUrl = this._sanitizer.bypassSecurityTrustUrl(this.dukanShop?.url);
        if (res?.state === StateStatuses.ACTIVE) {
          this._dialog.open(DukanSuccessfullyCreatedComponent, {
            width: '700px',
            panelClass: 'linked-store-dialog',
            direction: this.direction,
            data: {
              url: res.url,
              reactivated: false,
            },
          });
        }
      },
    });
  }

  public openStoreIntegrationModal(): void {
    if (!this.viewState.selectedStore.provider) return;

    const integrationsModalsOpeners = {
      [WOOCOMMERCE_PROVIDER_NAME]: this.openWoocommerceModal.bind(this),
      [YOUCAN_PROVIDER_NAME]: this.openYoucanModal.bind(this),
      [ZID_PROVIDER_NAME]: this.openZidModal.bind(this),
      [DUKAN_PROVIDER_NAME]: this.setupDukanDialog.bind(this),
    };

    const method =
      integrationsModalsOpeners[
        this.viewState.selectedStore.provider as keyof typeof integrationsModalsOpeners
      ];

    if (method) {
      method();

      this.viewState.currentCountryLinkedStore = {
        storeId: this.viewState.selectedStore?.storeId,
        market: this.viewState.selectedStore?.market,
        provider: this.viewState.selectedStore?.provider,
      } as UserStoreModel;
    }
  }

  public storeUrl(): string {
    if (
      this.viewState.currentCountryLinkedStore &&
      this.viewState.selectedStore.provider === this.viewState.currentCountryLinkedStore.provider
    ) {
      return this.viewState.currentCountryLinkedStore.storeId;
    }

    if (this.dukanShop?.url) {
      return this.dukanShop.url;
    }

    const storesUrls = {
      [WOOCOMMERCE_PROVIDER_NAME]: 'https://woocommerce.com/',
      [YOUCAN_PROVIDER_NAME]: 'https://youcan.shop/',
      [ZID_PROVIDER_NAME]: 'https://zid.store/',
      [EASY_ORDERS_PROVIDER_NAME]: 'https://www.easy-orders.net/',
    };

    return storesUrls[this.viewState.selectedStore.provider as keyof typeof storesUrls] || '';
  }

  public displayHowToConnectStore(): void {
    this._dialog.open(YoucanLinkVideoComponent, {
      width: '800px',
      data: {
        url: this.viewState.selectedStore.howToLinkVideo,
      },
    });
    this._logMixpanelEventUseCase.execute({ eventName: 'you_can_video_button_clicked' });
  }

  public currentStoreHasAPIKey(): boolean {
    return this.viewState.selectedStore.provider === EASY_ORDERS_PROVIDER_NAME;
  }

  public storeIsEnabled(store: NewStoreModel): boolean {
    if (store.provider === DUKAN_PROVIDER_NAME) {
      return this.isUserEligibleToDukan;
    }

    return true;
  }

  public disconnectStore(): void {
    const dialogRef = this._dialog.open(UnlinkStorePopUpComponent, {
      width: '450px',
      panelClass: 'linked-store-dialog',
      direction: this.direction,
      data: {
        provider: this.viewState.currentCountryLinkedStore?.provider,
        storeId: this.viewState.currentCountryLinkedStore?.storeId,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: () => {
        this.getUserStores();

        this.viewState.currentCountryLinkedStore = undefined;
      },
    });

    this._logMixpanelEventUseCase.execute({
      eventName: 'click_unlink_store_button',
      payload: {
        provider: this.viewState.currentCountryLinkedStore?.provider,
        storeId: this.viewState.currentCountryLinkedStore?.storeId,
      },
    });
  }

  public startStoreIntegration(): void {
    if (this.viewState.selectedStore.name === YOUCAN_PROVIDER_NAME) {
      const dialogRef = this._dialog.open(YoucanCheckoutPopUpComponent, {
        width: '900px',
        panelClass: 'linked-store-dialog',
        direction: this.direction,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res === true) {
          window.location.href = getStoreRedirectUri(this.viewState.selectedStore.name)!;
        }
      });
    }

    if (this.viewState.selectedStore.provider === WOOCOMMERCE_PROVIDER_NAME) {
      const insertLinkModal = this._dialog.open(WooCommerceModalComponent, {
        direction: this.direction,
        panelClass: 'linked-store-dialog',
        width: '524px',
      });

      insertLinkModal.afterClosed().subscribe((res) => {
        if (res === true) {
          window.location.href = getStoreRedirectUri(this.viewState.selectedStore.name)!;
        }
      });
    } else {
      window.location.href = getStoreRedirectUri(this.viewState.selectedStore.name)!;
    }
  }
}
